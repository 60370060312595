
.login-box {
    position: relative;
    
    fp-logo {
        position: absolute;
        top: 1em;
        right: 1em;
        
        svg {
            height: 20px;
            // float: right;
            margin: 4px 4px 0 0;
        }
    }
}


